<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 d-flex align-items-center justify-content-center">
      <!-- Login-->
      <b-col
        xs="12"
        sm="8"
        md="6"
        lg="4"
        class="d-flex align-items-center card p-5"
      >
        <b-img
          :src="require('@/assets/images/logo/breeze_blue.svg')"
        />
        <!-- form -->
        <b-col>
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="handleSubmit"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons-->
              <b-button
                type="submit"
                variant="primary"
                block
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BImg, BInputGroupAppend, BInputGroup, BForm, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      username: '',
      submitted: false,
      required,
      status: '',
    }
  },
  computed: {
    ...mapGetters({
      error: 'alert/error',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn
    },
  },
  watch: {
    error: {
      deep: true,
      handler() {
        this.$bvToast.toast('Check if your username and password are correct', {
          title: 'Sorry, something went wrong.',
          autoHideDelay: 4000,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'danger',
        })
      },
    },
  },
  created() {
    // reset login status
    this.$store.dispatch('authentication/logout')
  },
  methods: {
    handleSubmit() {
      this.submitted = true
      const { username, password } = this
      const { dispatch } = this.$store
      if (username && password) {
        dispatch('authentication/login', { username, password })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
